import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChatService } from 'app/main/apps/chat/chat.service';

@Component({
  selector: 'beplic-room-plan-info',
  templateUrl: './room-plan-info.component.html',
  styleUrls: ['./room-plan-info.component.scss'],
})
export class RoomPlanInfoComponent implements OnInit, OnChanges {
  @Input() room: string; // Cambia 'string' al tipo que necesites

  totalConversaciones: number;
  estadoEnvio: string = 'Inhabilitado';
  iniciadas: number;
  disponibles: number;
  height: number = 8;
  progress: number;
  isAlert: boolean;
  totalMetaConversaciones: number;

  constructor(private _chatService: ChatService) {}

  ngOnInit() {}

  getRoomLimitMessage(room: any) {
    if (room.room.channel.name === 'Whatsapp') {
      this._chatService.getRoomLimitMessage(room).subscribe(
        
        resp => {
            
          this.totalMetaConversaciones = resp.data.messageLimitRoom;
          this.disponibles =  this.totalMetaConversaciones - resp.data.messageTotalProcess;
          this.iniciadas = resp.data.messageTotalProcess;
          this.estadoEnvio = this.iniciadas >= this.totalMetaConversaciones ? 'Inhabilitado' : 'Habilitado';
          this.isAlert = this.iniciadas >= this.totalMetaConversaciones ? true : false;
          this.progress = (this.iniciadas * 100) / this.totalMetaConversaciones;
          
        },error => {
          this.totalConversaciones = undefined;
          this.disponibles = undefined;
          this.iniciadas = undefined;
          this.estadoEnvio = 'Sin definir';
          this.progress = undefined;
        }
      );
    } else {
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['room']) {
      this.getRoomLimitMessage(this.room);
    }
  }
}
