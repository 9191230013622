import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { environment } from 'environments/environment';
import { ScriptService } from 'app/services/script.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { FrontConfiguration } from 'app/config/front-configuration';
import { getConfigurationValue } from 'app/shared/util/common-functions';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { LoginService } from 'app/main/pages/authentication/login/login.service';

const META_PERMISSION_REQUEST_ENABLED = environment.META_PERMISSION_REQUEST_ENABLED;
let META_APP_ID = environment.META_APP_ID;
let META_SRC_SDK_SCRIPT = environment.META_SRC_SDK_SCRIPT;

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    currentUrl: string; // Variable para almacenar la URL actual
    isLogin: boolean = false;


    dopplerUrl: string;
    planExpireDate: string;
    showPlanMessage = false;
    expirationDate: number;
    frontConfig = FrontConfiguration;
    trialTypeAlert:string;
    borderType: string;
    alertIcon: string

    userProfile: CustomerAccount;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _scriptService: ScriptService,
        private renderer: Renderer2,
        private _fuseConfigService: FuseConfigService,
        private _router: Router, // Inyecta el Router
        @Inject(DOCUMENT) private document: any,
        private router: Router,
        private _localStorageService: LocalStorageService,
        private _loginService: LoginService
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.currentUrl = event.url;
                this.isLogin = this.currentUrl === '/pages/auth/login'; // Verifica si la URL es /login
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.currentUrl = this._router.url;

        // Load Facebook Script
        this.loadFacebookScript();

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        this._loginService.loggedIn$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((loggedIn: boolean) => {
                if (loggedIn) {
                    this.userProfile = this._localStorageService.getCurrentUser();
                    // Delay added so that it is not displayed on the login screen
                    setTimeout(() => {
                        this.getUserPlan();
                    }, 350);
                } else {
                    this.showPlanMessage = false;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    getUserPlan() {
        if (this.userProfile && this.userProfile.plan) {
            this.showPlanMessage = this.userProfile.plan.isFree;
            this.dopplerUrl = getConfigurationValue(FrontConfiguration.PARTNER_URL_PLAN);
            this.calculateExpirationDate(this.userProfile.plan.trialPeriodDate);
        } else {
            this.showPlanMessage = false;
        }
    }

    calculateExpirationDate(trialPeriodDate: string) {
                       
        const date1 = new Date(trialPeriodDate);
        const date2 = new Date();

        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
    
        // Validar si la fecha del periodo de prueba es mayor o igual a la actual
        if (date1 > date2) {
            const diffInMs = Math.abs(date2.getTime() - date1.getTime());
            let diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    
            // Ajustar si es mayor de 90 días
            if(diffInDays > 90){
                diffInDays--;
            }
    
            this.planExpireDate = `${this.getTrialMessage(diffInDays)} para finalizar tu periodo de prueba de Doppler Conversaciones`;
            this.trialTypeAlert = 'warning-new-user'
            this.borderType = 'border'
            this.alertIcon = 'error'
        } else {
            this.planExpireDate = 'Tu periodo de prueba ha finalizado, para continuar utilizando Doppler Conversaciones adquiere un plan.';
            this.trialTypeAlert = 'alert-new-user'
            this.borderType = ''
            this.alertIcon = 'warning'
        }

    }

    getTrialMessage(days: number){
        return days === 1 ? 'Resta 1 día': `Restan ${days} días`
    }

    loadFacebookScript() {
        // solo para solicitar permisos de whatsapp/instagram
        if (META_PERMISSION_REQUEST_ENABLED) {
            //META_APP_ID = '533275362263888'; // --> APP: WSP
            //META_APP_ID = '3786546154738749'; // --> APP: Beplic Comment
            META_APP_ID = '723456121749270'; // --> APP: Beplic
            META_SRC_SDK_SCRIPT = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0&appId={%META_APP_ID%}&autoLogAppEvents=1';
        }
        const scriptPath = META_SRC_SDK_SCRIPT.replace(/{%META_APP_ID%}/, META_APP_ID);

        const scriptElement = this._scriptService.loadFacebookScript(this.renderer, scriptPath);
        scriptElement.onload = () => { }
        scriptElement.onerror = () => {
            console.error('Could not load the Facebook Script!');
        };
    }
}
