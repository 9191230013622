import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';

import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { NavigationService } from 'app/navigation.service';
import { environment } from 'environments/environment';
import { checkByConfigAndRoleDoppler } from "../../../../../shared/util/common-functions";
import { LocalStorageService } from 'app/services/local-storage.service';
import { FrontConfiguration } from 'app/config/front-configuration';
import { AuthRoles } from 'app/main/pages/authentication/shared/model/enum/auth-roles.enum';


@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    currentUser: CustomerAccount;
    sub: any;

    public user: CustomerAccount = new CustomerAccount();

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;
    readonly VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;
    currentUserSession: any;
    isNavbarTopEnabled: boolean = false; // Initial value to only be displayed if the associated configuration is present
    isUserEnabled: boolean = true;
    frontConfiguration = FrontConfiguration;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private navigationService: NavigationService,
        private swPush: SwPush,
        private swUpdate: SwUpdate,
        private _localStorageService: LocalStorageService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {

                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                });
            }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getUserProfile();

        this.currentUserSession = this._localStorageService.getCurrentUser();

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();
                }
            }
            );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(navigationKey => {
                this.navigation = navigationKey ? this._fuseNavigationService.getCurrentNavigation() : null;

            });

        // get user information
        this.navigationService.onProfileUpdated.subscribe((config) => {
            this.getUserProfile();
        });

        // Get partner settings to hide the navbar-top
        this.isNavbarTopEnabled = checkByConfigAndRoleDoppler([FrontConfiguration.PARTNER_NAV_TOP_ENABLED], AuthRoles.ROLE_ATC);
    }

    getUserProfile(): void {
        this.currentUser = this._localStorageService.getCurrentUser();
        if (this.currentUser) {
            // this.user.userName = this.currentUser;
            this.user.lastName = this.currentUser.lastName;
            this.user.firstName = this.currentUser.firstName;
            this.user.email = this.currentUser.email;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    notifyme(): void {
        this.swPush.requestSubscription({
            serverPublicKey: this.VAPID_PUBLIC_KEY
        }).then(sub => {
            this.sub = sub;
        }).catch(err => console.error("Could not subscribe to notifications", err));
    }
}
