import { Directive, Input, Renderer2, ElementRef, OnChanges, SimpleChanges, HostListener, OnInit, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'app/services/local-storage.service';
import { fromEvent, Subscription } from 'rxjs';
import { calculateExpirationDate } from '../util/common-functions';

@Directive({
  selector: '[appDisableControl]'
})
export class DisableButtonDirective implements OnInit{

  subscription = new Subscription();

  constructor(private el: ElementRef, private renderer: Renderer2, private _localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    
    if (calculateExpirationDate(this._localStorageService.getCurrentUser().plan)) {
      // Usamos la propiedad directamente
      setTimeout(()=>{
        this.el.nativeElement.disabled = true;
        this.el.nativeElement.classList.add("mat-button-disabled")
      },1) //execute after 1 ms
    } else {
      this.el.nativeElement.disabled = false;
    }
  }

}