import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, catchError, startWith } from 'rxjs/operators';

import { AcademyCoursesService } from 'app/main/apps/academy/courses.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { DashboardService } from "app/shared/app-dashboards/dashboard/dashboard.service";
import { LocalStorageService } from 'app/services/local-storage.service';
import { fuseAnimations } from '@fuse/animations';
import { FrontConfiguration } from 'app/config/front-configuration';

@Component({
    selector: 'beplic-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: fuseAnimations
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input("isFromAdminPage") isFromAdminPage: boolean = true; // default value
    iframeUrl: SafeResourceUrl | undefined;
    dashboardReportOpen: boolean = false;

    indicators: IndicatorResponse;

    isButtonDisabled = true;

    // Variables para filtros
    userProfile: CustomerAccount;
    channels: any[] = [];
    currentChannel: string;
    searchForm: FormGroup;
    dashboardSwitch: boolean = false;
    isLoading = false;
    isError = false;

    frontConfiguration = FrontConfiguration;

    constructor(
        private _academyCoursesService: AcademyCoursesService,
        private _dashboardService: DashboardService,
        private fb: FormBuilder,
        private _localStorageService: LocalStorageService,
        private translate: TranslateService
    ) {
        this.searchForm = this.fb.group({
            fromDate: [null],
            toDate: [null],
            currentChannel: [0],
        });
        this.userProfile = _localStorageService.getCurrentUser();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // ----------------------

    ngOnInit() {
        this.getChannelsByPartnerConfig();
        this.initSearchForm();
    }

    ngAfterViewInit(): void { }

    ngOnDestroy() { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    initSearchForm() {
        this.searchForm.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                startWith(this.searchForm.value), // emite el valor inicial del formulario cuando inicia el componente
                tap(() => {
                    this.isLoading = true;
                    this.isError = false;
                }),
                switchMap(values => {
                    const dataFormatted = this.getSearchDataFormatted();
                    return this._dashboardService.getConversationIndicators(dataFormatted).pipe(
                        catchError(err => {
                            this.isError = true;
                            return of(null);
                        })
                    )
                }),
                tap(() => {
                    this.isLoading = false;
                })
            ).subscribe((results: IndicatorResponse) => {
                this.indicators = results;
            });
    }

    getTranslatedChannelName(channelId: number): string {
        if (channelId === 3) {
            return this.translate.instant('LABELS.CHANNEL.WEBBOT');
        } else {
            // Buscar el canal por ID y devolver su nombre traducido
            const channel = this.channels.find(c => c.id === channelId);
            return channel ? channel.name : '';
        }
    }

    getChannelsByPartnerConfig(): void {
        this._academyCoursesService.getChannelsByPartnerConfig().subscribe((data) => {
            data.forEach(element => {
                if (element.active) {
                    this.channels.push(element);
                }
            });
        });
    }

    clearFields() {
        this.searchForm.reset({
            fromDate: '',
            toDate: '',
            currentChannel: 0
        });
    }

    formatTiempoRespuesta(tiempo: number): string {
        if (tiempo === 0) {
            return '0';
        }
        const segundosTotales = Math.floor(tiempo / 1000);
        const minutos = Math.floor(segundosTotales / 60);
        const segundos = segundosTotales % 60;
        const horas = Math.floor(minutos / 60);
        const minutosRestantes = minutos % 60;

        const pluralizar = (cantidad: number, singular: string, plural: string): string => {
            return cantidad === 1 ? `${cantidad} ${singular}` : `${cantidad} ${plural}`;
        };

        if (horas === 1 && minutosRestantes === 0) {
            return '1 h';
        } else if (horas === 1 && minutosRestantes > 0) {
            return `1 h y ${minutosRestantes} min`;
        } else if (horas > 1 && minutosRestantes === 0) {
            return `${horas} hs`;
        } else if (horas > 1 && minutosRestantes > 0) {
            return `${horas} hs y ${minutosRestantes} min`;
        } else if (minutosRestantes > 0) {
            return `${minutosRestantes} min`;
        } else {
            return 'Menos de un minuto';
        }
    }

    getSearchDataFormatted() {
        let dataFormatted = null;
        const valorFromDate = this.searchForm.get('fromDate').value || null;
        const valorToDate = this.searchForm.get('toDate').value || null;

        const formatDate = (date: string | null): string | null => {
            return date ? new Date(date).toISOString() : null;
        };

        const commonFilters = {
            fromDate: formatDate(valorFromDate),
            toDate: formatDate(valorToDate),
            customerId: this.userProfile.customerId,
            channelId: this.searchForm.get('currentChannel').value || null,
        };

        if (!this.isFromAdminPage) {
            dataFormatted = {
                ...commonFilters,
                customerUsersId: this.userProfile.id,
            };
        } else {
            dataFormatted = { ...commonFilters };
        }
        return dataFormatted;
    }
}