import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';
import { ConversationFlowService } from 'app/main/apps/conversations-flow/providers/conversations-flow.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'beplic-assign-flow-list',
    templateUrl: './assign-flow-list.component.html',
    styleUrls: ['./assign-flow-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AssignFlowListComponent implements OnInit {
    countAssignedRooms: number = 0;
    flows = [];

    flowSelected: any;

    constructor(private translate: TranslateService,
        public matDialogRef: MatDialogRef<AssignFlowListComponent>,
        private conversationsFlowService: ConversationFlowService,
        @Inject(MAT_DIALOG_DATA) private _data: any,) { }

    ngOnInit() {
        this.flowSelected = this._data.flow;
        this.filterFreeAndAssignedRooms(this._data.rooms)
    }

    filterFreeAndAssignedRooms(rooms: any[]) {
        this.flows = rooms.filter(room =>  room.assignedAnotherFlow === false || (room.flowId !== null && room.flowId !== this.flowSelected.id));
        this.updateCountAssignedRooms(this.flows);
    }

    selectedItem(room: any) {      
        const flowKeywords = this.flowSelected.flow.drawflow.Home.data[36].data.triggerwords.split(',').map(word => word.trim());
        
        this.validateKeywordsOnEditRoom(this.flowSelected.id, flowKeywords, room);
    }

    submit() {
        const invalidSelection = this.flows.some(flow => flow.validSelection === false);
        if (!invalidSelection) {
            this.matDialogRef.close(['save', this.flows]);
        }        
    }

    checkValidFlowSelection(){
        return this.flows.some(flow => flow.validSelection === false);
    }

    updateCountAssignedRooms(customeruserRooms: any[]) {
        
        const assignedRooms = customeruserRooms.filter(customeruserRoom => customeruserRoom.assigned );
        // const assignedRoomss = customeruserRooms.filter(customeruserRoom => customeruserRoom.assigned && customeruserRoom.flowId === null);

        this.countAssignedRooms = assignedRooms.length;
    }

    validateKeywordsOnEditRoom(flowId: number, keywordList: string[], room: any){
        
        this.conversationsFlowService.validateKeywordsOnEditRoom(flowId, keywordList, room.roomId).subscribe(resp =>{

            if(resp !== "Sin coincidencias" && room.assigned === true){
                room.validSelection = false;
                this.showKeywordAlert();
            }else {
                room.validSelection = true;
            }

            const customerUserRoomToFind = this.flows.find(flow => flow.roomId === room.roomId);
            if (customerUserRoomToFind) {
                customerUserRoomToFind.assigned = room.assigned;
            } else {
                this.flows.push(room);
            }
    
            this.updateCountAssignedRooms(this.flows);
        });
    }

    showKeywordAlert(){
        Swal.fire({
            title: '¡Atención!',
            text: 'La sala seleccionada ya tiene un flujo con algunas de las palabras clave del flujo que intentas asignar. Ten en cuenta que una sala no puede tener flujos con las mismas palabras de inicio.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#e2ae2f',
            confirmButtonText: 'Aceptar',
            width: '40rem'
        });
    }

}
