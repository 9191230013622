import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { navigation } from 'app/navigation/navigation';
import { navigation_user } from 'app/navigation/navigation_user';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationSpanish } from 'app/navigation/i18n/es';
import { CustomerAccount } from './main/pages/authentication/shared/model/user.model';
import { NavigationService } from './navigation.service';
import { environment } from '../environments/environment';
import { AccountService } from './core/auth/account.service';
import { SessionService } from './main/pages/authentication/shared/services/session.service';
import { SocketService } from './main/apps/chat/socket.service';
import { LocalStorageService } from './services/local-storage.service';

const META_PERMISSION_REQUEST_ENABLED = environment.META_PERMISSION_REQUEST_ENABLED;
const PATH_EXTERNAL_LOGIN = environment.PATH_EXTERNAL_LOGIN;

declare const gtag: Function;

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    navigation_user: any;
    userProfile: CustomerAccount;
    navigation_admin: any;
    private doppler_title = "Doppler";
    private beplic_title = "Beplic";

    private doppler_favicon = "assets/doppler/favicon.ico";
    private beplic_favicon = "assets/beplic/favicon.ico";

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private navigationService: NavigationService,
        private router: Router,
        private titleService: Title,
        private _accountService: AccountService, // Service Initization to check doppler session, not deleted reference
        private _sessionService: SessionService,
        private _socketService: SocketService,
        private _localStorageService: LocalStorageService
    ) {
        // this.setGTagManager();
        this.addGAScript();
        this.getUserProfile();
        // Get default navigation
        this.navigation = navigation;
        this.navigation_user = navigation_user;

        const defaultLangMetaPermission = META_PERMISSION_REQUEST_ENABLED ? 'en' : 'es';

        // Add languages
        this._translateService.addLangs(['en', 'es']);

        // Set the default language
        this._translateService.setDefaultLang(defaultLangMetaPermission);

        // Set the navigation translations
        //this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationSpanish);

        // Use a language
        this._translateService.use(defaultLangMetaPermission);

        //this._translateService.get('welcomeMessage').subscribe((text: string) => { console.log('HERE: ', text); });

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('es');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            /** START : Code to Track Page View  */
            gtag('event', 'page_view', {
                page_path: event.urlAfterRedirects
            })
            /** END */
        })
    }

    getUserProfile() {
        this.userProfile = this._localStorageService.getCurrentUser();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.changeFaviconAndTitle();
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.getUserProfile();
                if (this.userProfile && this.userProfile.frontConfigurations.partner && this.userProfile.frontConfigurations.partner === 2) {
                    this.document.body.classList.add('theme-doppler');
                } else {
                    this.document.body.classList.add('theme-default');
                }

                // this.document.body.classList.add('theme-doppler');


            });

        /**
         * Cuando se recarga la pagina luego de logearse el método initMainConfiguration(...) 
         * obtiene los datos necesarios para que todo siga funcioanando. Sin embargo esté método
         * no debe llamarse cuando el usuario (ADMIN) ingrese de la Integración por que se esta 
         * llamando dos veces a este metodo lo cual genera redundancia de datos y genera que el menu 
         * de Doppler no se cargue
        */
        // TODO: esto se podria mejorar creando un componente que englobe a todos aquellos que solo son visibles cuando se ingresa a la app.
        const currentUrl = window.location.href;
        if (this.userProfile && !(currentUrl && currentUrl.includes(PATH_EXTERNAL_LOGIN))) {
            // Init navigation menu construction asynchronously
            this._sessionService.initMainConfiguration({ ...this.userProfile, isDopplerMenuLoaded: false });
        }

        // Evento para activar el hook ngOnDestroy luego de recargar/salir de la página
        window.onbeforeunload = () => this.ngOnDestroy();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Clean websocket connections
        this._socketService.disconnect();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    addGAScript() {
        let gtagScript: HTMLScriptElement = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
        document.head.prepend(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
    }

    private changeFaviconAndTitle(): void {
        // Cambiar el ícono dinamicamente
        const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = this.getFaviconUrl();
        document.getElementsByTagName('head')[0].appendChild(link);

        // Cambiar el título dinamicamente
        const newTitle = this.getPageTitle();
        this.titleService.setTitle(newTitle);
    }

    private getFaviconUrl(): string {
        const currentUrl = window.location.href;

        if (currentUrl.includes(environment.URL_DOPPLER)) {
            return this.doppler_favicon;
        } else {
            return this.beplic_favicon;
        }
    }

    private getPageTitle(): string {
        const currentUrl = window.location.href;

        if (currentUrl.includes(environment.URL_DOPPLER)) {
            return this.doppler_title;
        } else {
            return this.beplic_title;
        }
    }
}
