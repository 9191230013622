import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RequestBody } from '../detail/config/request-body';
import { drawflowDefault } from './utils';
import { LocalStorageService } from 'app/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { NameAvailabilityResponse } from '../detail/config/name-availability-response';


@Injectable()
export class ConversationFlowService {

  private flowUrl = `${UrlList.URL_MESSAGE_CHAT_BOT}/conversation-flows`;
  private urlContacts = UrlList.URL_CONTACTS;
  private urlRooms = UrlList.URL_ROOMS;

  labels: any;
  onShowPreLoading: BehaviorSubject<boolean>;
  private hasBlockNavigation: boolean = false;

  constructor(private _httpClient: HttpClient, private _localStorageService: LocalStorageService) {
    this.onShowPreLoading = new BehaviorSubject(false);
  }

  getIsNameAvailable(nameFlow: string): Observable<any> {
    const customerId = this._localStorageService.getCurrentUser ().customerId;

    return this._httpClient.get<NameAvailabilityResponse>(
      `${UrlList.URL_MESSAGE_CHAT_BOT}/conversation/v1/customer/${customerId}/name-flow/${nameFlow}`
    );
  }

  createConversationFlow(body: RequestBody): Observable<any> {
    return this._httpClient.post(this.flowUrl, body);
  }

  updateConversationFlow(body: RequestBody): Observable<any> {
    return this._httpClient.put(this.flowUrl, body);
  }

  getConversationFlow(conversationFlowId: number): Observable<any> {
    const url = `${this.flowUrl}/${conversationFlowId}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.get(url, { headers: headers });
  }

  getLabels(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this.urlContacts + UrlList.CUSTOMER_LABELS + this._localStorageService.getCurrentUser().customerId).subscribe((response: any) => {

        this.labels = response.sort((a, b) => {
          const nameA = a.label.name.toLowerCase();
          const nameB = b.label.name.toLowerCase();
          if (nameA < nameB)
            return -1;
          if (nameA > nameB)
            return 1;
          return 0;
        })
        resolve(this.labels);
      }, reject);
    });
  }


  getDrawflowConfigWelcome(): Observable<any> {
    return of({
      drawflow: {
        Home: {
          data: {
            '1': {
              id: 1,
              name: 'welcome',
              data: {},
              class: 'welcome',
              html: "\n <div>\n <div class=\"header-container\">👏 tutorial</div>\n <div class=\"box\">\n <div class=\"box-container\">\n <p>Comienza a diseñar tu <b>flujo conversacional</b> personalizando cada detalle.\n <p><b>Elementos disponibles:</b>\n <p>Flujos <br>\ Menús<br>\n Opciones<br>\n Imágenes<br>\n y más.<br>\n </p>\n <br>\n <p><b><u>Atajos:</u></b></p>\n 💠 Click primario == Mover<br>\n ❌ Click secundario == Eliminar<br>\n 🔍 Ctrl + Rueda == Zoom<br>\n ...</p>\n </div>\n </div>\n </div>\n ",
              typenode: false,
              inputs: {},
              outputs: {},
              pos_x: 50,
              pos_y: 50,
            },
          },
        },
      },
    }).pipe(delay(1000));
  }

  /**
   * Method that returns a default flow, displaying the FLUJO, PRESENTACIÓN, DATOS REQUERIDOS, 
   * and MENU PRINCIPAL components once loaded in the view.
   * 
   * @returns An observable that emits the default flow.
   */
  getDrawflowDefault(): Observable<any> {
    // The method returns a JSON object that represents the default flow
    return of(
      drawflowDefault
    ).pipe(delay(1000));
  }


  setBlockNavigation(isBlocked: boolean) {
    this.hasBlockNavigation = isBlocked;
  }

  getBlockNavigation() {
    return this.hasBlockNavigation;
  }

  validateKeywordsOnEditRoom(flowId: number, keywordList: string[], roomId?: number){
    const body = {
      flowId,
      keywordList,
      roomId
    }
    return this._httpClient.post(`${this.urlRooms}/room/v1/validate-keywords-on-edit-flow`, body, { responseType: 'text' });
  }

}
