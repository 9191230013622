import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { TodoService } from './todo.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { SocketService } from './socket.service';
import { SessionService } from 'app/main/pages/authentication/shared/services/session.service';
import { RequestChatBotResumeDTO } from 'app/interfaces/dto/request-chatbot-resume-dto.interface';
import { NameApiProvider } from '../enums/name-api-provider';
import { LocalStorageService } from 'app/services/local-storage.service';
import { formatTodayDate } from 'app/shared/util/common-functions';

@Injectable()
export class ChatService implements Resolve<any> {

    title = '-';
    description = 'WebSocket';

    greetings: string[] = [];
    disabled = true;
    text: string;
    public stompClient: any;
    private urlRooms = UrlList.URL_ROOMS;

    onContactSelectedFromChat: BehaviorSubject<any>;
    onContactLabelsUpdate: Subject<any>;
    onContactInfo: BehaviorSubject<any>;
    onFollowedMessages: BehaviorSubject<any>;
    contacts: any[];
    chats: any[];
    user: any;
    contact: any;
    onSesionChat: BehaviorSubject<any>;
    onChatSelected: Subject<any>; // Change to Subject to not broadcast the last one after it is filtered and chat change from another room
    onContactSelected: BehaviorSubject<any>;
    onChangeRoom: BehaviorSubject<any>;
    onChatsUpdated: Subject<any>;
    onUserUpdated: Subject<any>;
    onLeftSidenavViewChanged: Subject<any>;
    onRightSidenavViewChanged: Subject<any>;
    onMailsLoaded: BehaviorSubject<any>;
    onToggleSideNav: BehaviorSubject<any>;
    onMailSelected: Subject<any>;
    routeParams: any;
    _this: any;

    connectedPromise: any;
    connection: Promise<any>;
    subscriber = null;
    userProfile: CustomerAccount;
    roomFilter: any = '';
    sessionStatus: any;
    followedMessages: any[] = [];
    filterActive: string = 'ALL';
    filterLabelsActive = [];
    attachments: any[] = [];
    pageOfMessages$: Subscription;
    pagesOfFilteredMessages$: Subscription;

    // cargarMensajeChatMethodCallSource = new Subject<any>();
    loadMessagesChatMethodCallSource: BehaviorSubject<any>;

    private messagePageSize = 20;
    private contactsPageSize = 25;

    private urlConversation = UrlList.URL_CONVERSATIONS;
    private urlMainDoor = UrlList.URL_MAINDOOR;
    private urlUsers = UrlList.URL_USERS;
    private urlFile = UrlList.URL_FILE;
    private urlConversationRoomsIntegra = UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private todoService: TodoService,
        private socketServie: SocketService,
        private sessionService: SessionService,
        private _localStorageService: LocalStorageService
    ) {
        // Set the defaults
        this.onChatSelected = new Subject();
        this.onContactSelected = new BehaviorSubject(null);
        this.onContactSelectedFromChat = new BehaviorSubject(null);
        this.onChangeRoom = new BehaviorSubject(null);
        this.onChatsUpdated = new Subject();
        this.onUserUpdated = new Subject();
        this.onLeftSidenavViewChanged = new Subject();
        this.onRightSidenavViewChanged = new Subject();
        this.onContactInfo = new BehaviorSubject(null);
        this.onFollowedMessages = new BehaviorSubject(null);
        this.onMailSelected = new BehaviorSubject(null);
        this.onMailsLoaded = new BehaviorSubject(null);
        this.onToggleSideNav = new BehaviorSubject(null);
        this.loadMessagesChatMethodCallSource = new BehaviorSubject(null);
        this.onSesionChat = new BehaviorSubject([]);
        this.onContactLabelsUpdate = new Subject();
    }

    /**
     * Resolver
     * 
     * Se activa cuando se toca la opción "Chats" del menu lateral
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.getUserProfile();
        this.routeParams = route.params;
        if (this.routeParams.filterId) {
            return new Promise((resolve, reject) => {
                this.getContacts(0).then((contacts) => {
                    this.onChangeRoom.next(contacts);
                    resolve(contacts);
                },
                );
            });
        } else {
            this.onChangeRoom.next(null);
        }
    }

    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();
    }

    private createConnection(): Promise<any> {
        return new Promise((resolve, reject) => this.connectedPromise = resolve);
    }

    setConnected(connected: boolean) {
        this.disabled = !connected;

        if (connected) {
            this.greetings = [];
        }
    }

    disconnect() {
        if (this.stompClient != null) {
            this.stompClient.disconnect();
        }

        this.setConnected(false);
    }

    updateChat(chat): Observable<any> {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));

        const body = {
            id: chat.chatId,
            roomId: chat.roomId,
            contactId: chat.contactId,
            contactName: chat.contactName,
            lastMessageDate: chat.lastMessageDate,
            state: 'NOTRESPONSE',
            conversationMode: chat.conversationMode,
            customerUserId: chat.customerUserId,
            customeruserName: chat.customeruserName,
            expiredDate: chat.expiredDate,
            sessionState: chat.sessionState
        }
        // chat.state = "NOTRESPONSE";
        return this._httpClient.put(this.urlConversation + UrlList.UPDATE_CHAT, body);

    }

    showGreeting(message): void {
        this.greetings.push(message);
    }

    /**
     * Get chat
     * // TODO: ver para que se llame una vez los grupos cuando se cambia de sala
     * @param contactId
     */
    getChat(chat: any): void {
        this.onContactSelectedFromChat.next(chat);
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));

        // LLama al endpoint para cargue los grupos
        if (currentRoom.room.channel.id !== 5) {
            this.todoService.getGroups(currentRoom);
        }

        if (chat) {
            const sizePage = currentRoom.room.channel.id === 5 ? 1000000 : this.messagePageSize;
            if ('message' in chat && chat.message) {
                if (chat.message && currentRoom.room.channel.id !== 5) {
                    // Cancel subscription if in progress
                    this.cancelSubscription('pagesOfFilteredMessages$');
                    this.pagesOfFilteredMessages$ = this.getPagesOfFilteredMessages(chat, 0, sizePage).subscribe((filteredChat: any[]) => {
                        this.onChatSelected.next(filteredChat);
                    }, error => console.error(error));
                } else {
                    // Cancel subscription if in progress
                    this.cancelSubscription('pageOfMessages$');
                    this.pageOfMessages$ = this.getPageOfMessages(chat, this.userProfile.id, this.userProfile.customerId, 0, sizePage).subscribe((messages: any) => {
                        this.onChatSelected.next(messages);
                    });
                }
            } else {
                // Cancel subscription if in progress
                this.cancelSubscription('pageOfMessages$');
                this.pageOfMessages$ = this.getPageOfMessages(chat, this.userProfile.id, this.userProfile.customerId, 0, sizePage).subscribe((messages: any) => {
                    this.onChatSelected.next(messages);
                });
            }
        }
    }

    getPagesOfFilteredMessages(chat: any, page?: number, size?: number): Observable<any> {
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.get(this.urlConversation + `/messages/chat/${chat.chatId}/message/${chat.message.id}`, { params });
    }

    getPageOfMessages(chat, customeruserId: number, customerId: number, page?: number, size?: number): Observable<any> {
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.get(UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA + '/integra/messages/findByChat/' + chat.chatId + '/customer/' + customerId + '/customeruser/' + customeruserId, { params });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(currentPage?: number, updateModality?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const user: any = this._localStorageService.getCurrentUser();
            const roomData = JSON.parse(sessionStorage.getItem('currentRoom'));
            if (roomData && user) {
                let page = currentPage || 0;
                const update = updateModality || '';
                let filters: string[] = [];
                if (update === 'update-modality') {
                    page = 0;
                }
                if (this.userProfile.customerUserPreferences.length === 0 || this.userProfile.customerUserPreferences[0].value === 'ALL') {
                    this.roomFilter = ["NOTVIEW", "NOTRESPONSE", "RESPONSE", "CLOSED", "NOTDELIVERED", "STANDBY"];
                    this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize).subscribe((response: any) => {
                        if (update === 'update-modality') {
                            this.onChangeRoom.next(response);
                        }
                        resolve(response);
                    }, reject);

                } else {
                    if (this.userProfile.customerUserPreferences[0].value === 'IN_PROGRESS') {
                        filters = ['NOTVIEW', 'NOTRESPONSE', 'RESPONSE', 'NOTDELIVERED', 'STANDBY'];
                    } else {
                        filters.push(this.userProfile.customerUserPreferences[0].value);
                    }
                    this.roomFilter = filters;
                    if ((this.roomFilter[0] === 'MASSIVE' && roomData.room.channel.id !== 1) || (this.roomFilter[0] === 'FOLLOWED' && roomData.room.channel.id === 5)) {
                        this.changePreferences('ALL');
                        this.roomFilter = ["NOTVIEW", "NOTRESPONSE", "RESPONSE", "CLOSED", "NOTDELIVERED", "STANDBY"];
                        this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize).subscribe((response: any) => {
                            if (update === 'update-modality') {
                                this.onChangeRoom.next(response);
                            }
                            resolve(response);
                        }, reject);
                        // Guard clause: to end function
                        return;
                    }
                    if (this.roomFilter[0] === 'FOLLOWED') {
                        this.roomFilter = [];
                        this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize, null, true).subscribe(data => {
                            if (update === 'update-modality') {
                                this.onChangeRoom.next(data);
                            }
                            resolve(data);
                        });
                        // Guard clause: to end function
                        return;
                    }
                    if (this.roomFilter[0] === 'MASSIVE') {
                        this.roomFilter = [];
                        this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize, null, null, true).subscribe(data => {
                            if (update === 'update-modality') {
                                this.onChangeRoom.next(data);
                            }
                            resolve(data);
                        });
                        // Guard clause: to end function
                        return;
                    }
                    if (this.roomFilter[0] === 'EXPIRED') {
                        this.roomFilter = [];
                        this.sessionStatus = 'EXPIRED';
                        this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize, null, null, null, null, this.sessionStatus).subscribe(data => {
                            if (update === 'update-modality') {
                                this.onChangeRoom.next(data);
                            }
                            resolve(data);
                        });
                        // Guard clause: to end function
                        return;
                    }
                    if (this.roomFilter[0] === 'LABELS') {
                        this.roomFilter = ["NOTVIEW", "NOTRESPONSE", "RESPONSE", "CLOSED", "NOTDELIVERED", "STANDBY"];
                        let mylabelIds = [];
                        if (this.userProfile && this.userProfile.customerUserPreferences.length > 0) {
                            mylabelIds = this.userProfile.customerUserPreferences[0].optionsId.split(',').map(el => +el);
                        }
                        this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize, null, null, null, mylabelIds).subscribe(data => {
                            if (update === 'update-modality') {
                                this.onChangeRoom.next(data);
                            }
                            resolve(data);
                        });
                        // Guard clause: to end function
                        return;
                    }

                    this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, user, roomData, page, this.contactsPageSize).subscribe(data => {
                        if (update === 'update-modality') {
                            this.onChangeRoom.next(data);
                        }
                        resolve(data);
                    });
                }
            }
        });
    }

    getContactsByOpenRoomOrAdminUser(roomId: number, customerId: number, page?: number, size?: number): Observable<any> {
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.get(UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA + '/integra/chats/findByRoomId/' + roomId + '/customer/' + customerId, { params });
    }

    getContactsByPrivateRoomAndUser(roomId: number, customerUserId: number, page?: number, size?: number): Observable<any> {
        const body = { roomId: roomId, customerUserId: customerUserId };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post(this.urlConversation + UrlList.FIND_BY_ROOM_AND_USER, body, { params });
    }

    // getContactsByPrivateRoomAndUserAndLabels(roomId: number, customerUserId: number, page?: number, size?: number): Observable<any> {
    //     const body = { roomId: roomId, customerUserId: customerUserId, states: ['NOTVIEW', 'NOTRESPONSE', 'RESPONSE', 'CLOSED', 'NOTDELIVERED', 'STANDBY']};
    //     const params = new HttpParams()
    //         .set('page', page.toString())
    //         .set('size', size.toString());
    //     return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chats/findByStatesAndRoomIdAndCustomerUserId', body, { params });
    // }

    getContactsByPrivateRoomAndUserAndLabels(roomId: number, customerUserId: number, filters: any, page?: number, size?: number): Observable<any> {
        const body = { roomId: roomId, customerUserId: customerUserId, states: filters };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chats/findByStatesAndRoomIdAndCustomerUserId', body, { params });
    }

    findContactAndChatByRoomIAndTextAndStatesAndFollowup(filters: any, user: any, roomData: any, page?: number, size?: number, text?: any, followedMessages?: any, massMessage?: any, labels?: any[], sessionStatus?: any): Observable<any> {
        const body = {
            customerId: user.customerId,
            roomId: roomData.room.id,
            channelId: roomData.room.channel.id,
            customerUserId: roomData.room.modality === 'PRIVATE' && user.authorities.includes('ROLE_ATC') ? user.id : null,
            text: text ? text : null,
            states: filters,
            followUpMessage: followedMessages ? followedMessages : null,
            massMessage: massMessage ? massMessage : null,
            labels: labels ? labels : null,
            sessionStatus: sessionStatus ? sessionStatus : null,
        };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/contacts/findContactAndChatByRoomIAndTextAndStatesAndFollowup', body, { params });
    }
    /**
     * Create new chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    createNewChat(contact): Promise<any> {
        return new Promise((resolve, reject) => {
            const body = {
                contactId: contact.id,
                contactName: contact.name,
                conversationMode: "A",
                createdBy: this.userProfile.firstName + ' ' + this.userProfile.lastName,
                createdDate: contact.creationDate,
                customerUserId: this.userProfile.id,
                lastMessageDate: contact.creationDate,
                lastModifiedBy: null,
                lastModifiedDate: null,
                roomId: contact.roomId,
                state: "NOTVIEW"
            }
            return new Promise((resolve, reject) => {
                this._httpClient.post('/services/bepliccoreconversation/api/chats', body).subscribe((response: any) => {
                    resolve(response);
                }, reject => {
                    resolve(null);
                });
            });
        });
    }

    /**
     * Select contact
     *
     * @param contact
     */
    selectContact(contact): void {
        this.onContactSelected.next(contact);
    }

    /**
     * Set user status
     *
     * @param status
     */
    setUserStatus(status): void {
        this.user.status = status;
    }

    /**
     * Update user data
     *
     * @param userData
     */
    updateUserData(userData): void {
        this._httpClient.post('api/chat-user/' + this.user.id, userData)
            .subscribe((response: any) => {
                this.user = userData;
            }
            );
    }

    /**
     * Update the chat dialog
     *
     * @param chatId
     * @param dialog
     * @returns {Promise<any>}
     */
    updateDialog(chatId, dialog): Promise<any> {
        return new Promise((resolve, reject) => {

            const newData = {
                id: chatId,
                dialog: dialog
            };

            this._httpClient.post('api/chat-chats/' + chatId, newData)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                }, reject);
        });
    }

    sendMessage(message: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlMainDoor + UrlList.SEND_IMAGE, message)
                .subscribe(
                    updatedChat => {
                        resolve(updatedChat);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    getAvailableTemplateMessageCount(roomId: number, currentDate: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${this.urlConversationRoomsIntegra}/integra/get-available-template-message-count?roomId=${roomId}&date=${currentDate}`)
                .subscribe(
                    updatedChat => {
                        resolve(updatedChat);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    sendEmail(email: any): Observable<any> {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        this.userProfile = this._localStorageService.getCurrentUser();
        const currentChat = JSON.parse(sessionStorage.getItem('currentChat'));

        const fd = new FormData();
        fd.append('messageType', 'text');
        fd.append('channel', currentRoom.room.channel.id);
        fd.append('phoneNumber', '');
        fd.append('phoneNumberBusiness', '');
        fd.append('customerUserId', this.userProfile.id.toString());
        fd.append('messageDir', 'OUT');
        fd.append('text', email.message);
        fd.append('customeruserName', this.userProfile.firstName + ' ' + this.userProfile.lastName);
        fd.append('roomId', currentRoom.room.id);
        fd.append('email', email.to);
        fd.append('roomEmail', currentRoom.room.email);
        fd.append('subject', email.subject);
        fd.append('emailCC', email.cc && email.cc.length !== 0 ? email.cc.join(";") : '');
        fd.append('nameApiProvider', NameApiProvider.EMAIL);
        if (email.attachments && email.attachments.length !== 0) {
            email.attachments.forEach(f => {
                fd.append('files', f.file);
            });
        }
        if (!email.attachments) {
            this.attachments.forEach(f => {
                fd.append('files', f.file);
            });
        }
        return this._httpClient.post(this.urlMainDoor + '/maindoor/emailOut', fd);
    }

    getContact(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(UrlList.URL_CONTACTS + UrlList.GET_CONTACT_BY_ID + '/' + id)
                .subscribe(updatedChat => {
                    resolve(updatedChat);
                }, reject);
        });
    }

    /**
     * Get chats
     *
     * @returns {Promise<any>}
     */
    getChats(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-chats')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/chat-user')
                .subscribe((response: any) => {
                    resolve(response[0]);
                }, reject);
        });
    }

    deleteMessage(message: any): Promise<any> {
        message.state = 'DELETED'
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlConversation + UrlList.UPDATE_MESSAGE, message)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }

    sendImage(image: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlMainDoor + UrlList.SEND_IMAGE, image)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }

    filterContactsForMessageText(search: string, currentPage: number, followUpMessages: boolean, massMessage: boolean, labels: any[], sessionStatus: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
            const currentUser = this._localStorageService.getCurrentUser();

            this.findContactAndChatByRoomIAndTextAndStatesAndFollowup(this.roomFilter, currentUser, currentRoom, currentPage, this.contactsPageSize, search, followUpMessages, massMessage, labels, sessionStatus).subscribe((response: any) => {
                resolve(response);
            }, reject);
            // if (currentUser.authorities.includes('ROLE_ADMIN')) {
            //     this.findByRoomIdAndMessageTextAndLabels(currentRoom.room.id, search, currentPage, this.contactsPageSize).subscribe((resp: any) => {
            //         resolve(resp);
            //     });
            // } else if (currentUser.authorities.includes('ROLE_ATC')) {

            //     if (currentRoom.room.modality === 'PRIVATE') {
            //         this.findByRoomIdAndMessageTextAndCustomeruserAndLabels(currentRoom.room.id, search, this.userProfile.id, 0, this.contactsPageSize).subscribe((resp: any) => {
            //             resolve(resp);
            //         });
            //     } else if (currentRoom.room.modality === 'OPEN') {
            //         this.findByRoomIdAndMessageTextAndLabels(currentRoom.room.id, search, currentPage, this.contactsPageSize).subscribe((resp: any) => {
            //             resolve(resp);
            //         });
            //     }
            // }

        });
    }


    filterContactsByState(filter: string[], currentPage: number, followedMessages?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
            const currentUser = this._localStorageService.getCurrentUser();

            if (currentUser.authorities.includes('ROLE_ADMIN')) {
                this.findContactsByState(currentRoom.room.id, this.userProfile.customerId, filter, currentPage, this.contactsPageSize, followedMessages).subscribe((resp: any) => {
                    resolve(resp);
                });
            } else if (currentUser.authorities.includes('ROLE_ATC')) {

                if (currentRoom.room.modality === 'PRIVATE') {
                    this.findContactsByStateAndCustomer(currentRoom.room.id, this.userProfile.id, filter, currentPage, this.contactsPageSize, followedMessages).subscribe((resp: any) => {
                        resolve(resp);
                    });
                } else if (currentRoom.room.modality === 'OPEN') {
                    this.findContactsByState(currentRoom.room.id, this.userProfile.customerId, filter, currentPage, this.contactsPageSize, followedMessages).subscribe((resp: any) => {
                        resolve(resp);
                    });
                }
            }

        });
    }

    // Método admin
    findContactsByState(roomId: number, customerId: number, filter: any, page?: number, size?: number, followedMessages?: boolean): Observable<any> {
        const body = {
            states: filter,
            followUpMessage: followedMessages
        };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post(UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA + '/integra/chats/findByStatesAndRoomId/' + roomId + '/customer/' + customerId, body, { params });
    }

    // Método user atc
    findContactsByStateAndCustomer(roomId: number, customerUserId: number, filter: any, page?: number, size?: number, followedMessages?): Observable<any> {
        const body = {
            roomId: roomId,
            customerUserId: customerUserId,
            states: filter,
            followUpMessage: followedMessages
        };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post(UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA + '/integra/chats/findByStatesAndRoomIdAndCustomerUserId', body, { params });
    }

    findByRoomIdAndMessageText(roomId: number, text: string, page?: number, size?: number): Observable<any> {
        const body = { roomId: roomId, messageTxt: text };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chats/findByRoomIdAndMessageText', body, { params });
    }

    findByRoomIdAndMessageTextAndLabels(roomId: number, text: string, page?: number, size?: number): Observable<any> {
        // const body = { roomId: roomId, messageTxt: text };
        // const params = new HttpParams()
        //     .set('page', page.toString())
        //     .set('size', size.toString());
        // return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chats/findByRoomIdAndMessageText', body, { params });

        const body = {
            customerId: 2,
            roomId: roomId,
            channelId: 1,
            customerUserId: null,
            text: text
        };

        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/contacts/findByRoomIdAndMessageText', body, { params });

    }

    findByRoomIdAndMessageTextAndCustomeruser(roomId: number, text: string, customerUserId: number, page?: number, size?: number): Observable<any> {
        const body = { roomId: roomId, messageTxt: text, customerUserId: customerUserId };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chats/findByRoomIdAndMessageTextAndCustomeruser', body, { params });
    }

    findByRoomIdAndMessageTextAndCustomeruserAndLabels(roomId: number, text: string, customerUserId: number, page?: number, size?: number): Observable<any> {
        const body = { roomId: roomId, messageTxt: text, customerUserId: customerUserId };
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString());
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chats/findByRoomIdAndMessageTextAndCustomeruser', body, { params });
    }

    /**
     * Fuction to update conversation mode.
     * @param data the data to update.
     */
    updateConversationMode(data: any): Observable<any> {
        return this._httpClient.put(this.urlConversation + '/chats', data);
    }

    findAndSetNewUser(userId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlUsers + '/customer-users/findById/' + userId)
                .subscribe((response: any) => {
                    resolve(response);
                }, error => reject(error));
        });
    }

    updateChatState(data: any): Observable<any> {
        return this._httpClient.post(this.urlConversation + UrlList.CLOSE_CHAT, data);
    }

    getPageSizeOfMessages(): number {
        return this.messagePageSize;
    }

    getPageSizeOfContacts(): number {
        return this.contactsPageSize;
    }

    getRooms(): Promise<any> {
        const body = {
            customeruserId: this.userProfile.id,
            customersId: this.userProfile.customerId
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlRooms + UrlList.GET_CUSTOMER_ROOMS, body).subscribe((response: any) => {
                sessionStorage.removeItem('myRooms');
                sessionStorage.setItem('myRooms', JSON.stringify(response));
                this.socketServie.disconnect();
                this.socketServie.initStompConnection(sessionStorage.getItem('hostName'));
                resolve(response);
            }, reject);
        });
    }

    changePreferences(value: any): Promise<any> {
        const customerUserPreferences = {
            id: null,
            optionsId: null
        }
        this.getUserProfile();

        if (this.userProfile.customerUserPreferences.length !== 0) {
            customerUserPreferences.id = this.userProfile.customerUserPreferences[0].id;
            customerUserPreferences.optionsId = this.userProfile.customerUserPreferences[0].optionsId;
        }

        const body = {
            id: customerUserPreferences.id,
            value: value,
            customerUsers: {
                id: this.userProfile.id
            },
            preferences: {
                name: 'LAST_FILTER_CHAT_BY_STATE'
            },
            optionsId: customerUserPreferences.optionsId
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post('/services/beplicoreuser/api/customer-user-preferences', body).subscribe((response: any) => {
                if (this.userProfile.customerUserPreferences.length !== 0) {
                    this.userProfile.customerUserPreferences[0].value = response.value;
                    this._localStorageService.setCurrentUser(this.userProfile);
                }
                resolve(response);
            }, reject);
        });
    }


    followMessage(message: any): Promise<any> {
        const body = {
            chatId: message.chatId,
            messageId: message.id,
            createdBy: message.createdBy
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post('/services/bepliccoreconversation/api/follow-up-messages', body).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getFollowedMessages(chatId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('/services/bepliccoreconversation/api/follow-up-messages/chat/' + chatId).subscribe((response: any) => {
                this.followedMessages = response;
                resolve(response);
            }, reject);
        });
    }

    getFollowedMessagesObs(chatId: number) {
        return this._httpClient.get('/services/bepliccoreconversation/api/follow-up-messages/chat/' + chatId);
    }

    resolveFollowedMessages(message, chatId): Promise<any> {
        const body = {
            id: message.id,
            state: 'FINALIZED',
            chatId: chatId,
            createdDate: message.createdDate,
            expirationDate: message.expirationDate,
            messageId: message.messageDTO.id,
            createdBy: message.createdBy,
            lastModifiedBy: message.lastModifiedBy
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put('/services/bepliccoreconversation/api/follow-up-messages', body).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    findContactByPhoneOrEmail(form): Promise<any> {
        const body = {
            customerId: this.userProfile.customerId,
            channelId: 1,
            roomId: JSON.parse(sessionStorage.getItem('currentRoom')).room.id,
            contact: {
                phone: form.phone.substr(1),
                email: '',
                userfId: ''
            }
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post('/services/bepliccorecontact/api/contacts/findByPhoneAndCustomerId', body).subscribe((response: any) => {
                resolve(response);
            }, reject => {
                resolve(null);
            });
        });
    }

    createContacts(contact): Promise<any> {

        var urlencoded = new URLSearchParams();
        urlencoded.append("message[uid]", "7D11DD3F90269D20FED63BEEA4FkF3CF41");
        urlencoded.append("message[dtm]", "1556749000");
        urlencoded.append("message[body][text]", "Primer mensaje de prueba");
        urlencoded.append("token", "21bc1e7dc412ee9fa9c5bf975ec9ebeb5cee131fca929dfdf");
        urlencoded.append("contact[name]", contact.firstName + ' ' + contact.lastName);
        urlencoded.append("uid", "5493886029736");
        urlencoded.append("message[cuid]", "888888894");
        urlencoded.append("message[type]", "chat");
        urlencoded.append("contact[uid]", contact.phone.substr(1));
        urlencoded.append("event", "message");
        urlencoded.append("message[dir]", "i");
        urlencoded.append("message[ack]", "3");
        urlencoded.append("contact[type]", "user");

        return new Promise((resolve, reject) => {
            fetch("/services/bepliccoremessagemqmaindoorin/api/maindoor", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: urlencoded,
                redirect: 'follow'
            }).then(response => {
                response.text();
                resolve(response);
            }).catch(error => console.error('error', error));
        });
    }

    getContactLabels(contactId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('/services/bepliccorecontact/api/contact-labels/findByContactId/' + contactId).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getCustomerUserProfileById(id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('/services/beplicoreuser/api/customer-users/findById/' + id).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    updateEmaiListManually(customerId: number, roomId: number): Observable<any> {
        return this._httpClient.get(`${UrlList.URL_REACTIVE_MESSAGES}/customer/${customerId}/room/${roomId}/polling`);
    }

    downloadUrl(url: string, fileName: string) {
        const a: any = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
    };

    updateEmailState(mail): Observable<any> {
        const body = {
            id: mail.id,
            state: "READ"
        }
        return this._httpClient.post('/services/bepliccoreconversation/api/messages/updateState', body);
    }

    updateEmailConfim(message): Observable<any> {
        const body = {
            id: message.id,
            chatId: message.chatId,
            emailState: "UPDATED"
        }
        return this._httpClient.post('/services/bepliccoreconversation/api/messages/updateEmail', body);
    }

    getAttachments() {
        return this.attachments;
    }
    setAttachments(attachments: any[]) {
        this.attachments = attachments;
    }

    getInfoSesion(chatId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('/services/bepliccoreconversation/api/chatSession/findByChatId/' + chatId).subscribe(response => {
                this.onSesionChat.next(response);
                resolve(response);
            }, error => reject(error))
        })
    }

    getInfoSessionObs(chatId) {
        return this._httpClient.get('/services/bepliccoreconversation/api/chatSession/findByChatId/' + chatId);
    }

    updateSession(chatSessionId, status) {
        const body = {
            id: chatSessionId,
            status
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post('/services/bepliccoreconversation/api/chatSession/updateStatus', body).subscribe(response => {
                resolve(response);
            }, reject)
        })
    }

    getRoomById(idRoom) {
        return this._httpClient.get('/services/bepliccoreroom/api/rooms/' + idRoom);
    }

    getMessagesByLastChatBotSession(data: RequestChatBotResumeDTO) {
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/chatbot/resume/getChatBotResume', data);
    }

    private cancelSubscription(name: string) {
        if (name && this[name]) {
            this[name].unsubscribe();
            this[name] = null;
        }
    }

    getRoomLimitMessage(room: any): Observable<any> {       
        const body = {
            roomId: room.room.id,
            scheduledDate: formatTodayDate(),
            messageAmount: 0
        }
        return this._httpClient.post('/services/bepliccoreconversationroomsintegra/api/integra/v1/campaign/validate-message', body);
    }

}
